/* eslint-disable max-lines, no-restricted-syntax, no-restricted-properties, no-empty, no-sequences, radix, max-len, no-restricted-globals, no-control-regex */

export default {
  getPlatformInfo(bizcode) {
    const platform = {
      weishi: '微视',
      mokuaitv: '星选',
      streamer: '无敌主播'
    }
    return platform[bizcode]
  },

  // 补全数字, 不够 2 位用 0 补全
  formatNumber(number) {
    return number < 10 ? `0${number}` : number
  },

  // 生成min-max随机数
  randomNum(min, max) {
    const range = max - min
    const rand = Math.random()
    const num = min + Math.floor(rand * range)
    return num
  },

  // 处理 Oct 26, 2017 11:55:47 AM
  // todo 增加 format 定制
  formatDate(str, onlyDate) {
    if (!str) {
      return ''
    }

    let date
    // 兼容 safari
    if (str.match && str.match(/\d+-\d+-\d+ \d+:\d+:\d+/)) {
      date = this.newDate(str)
    } else {
      date = new Date(str)
    }

    const y = date.getFullYear()
    const m = this.formatNumber(date.getMonth() + 1)
    const d = this.formatNumber(date.getDate())
    const h = this.formatNumber(date.getHours())
    const min = this.formatNumber(date.getMinutes())
    const s = this.formatNumber(date.getSeconds())

    if (onlyDate) {
      return `${[y, m, d].join('-')}`
    }
    return `${[y, m, d].join('-')} ${[h, min, s].join(':')}`
  },

  // todo 增加 format 定制
  // 开始小时 00 00 00
  formatStartDate(str, onlyDate) {
    if (!str) {
      return ''
    }

    let date
    // 兼容 safari
    if (str.match && str.match(/\d+-\d+-\d+ \d+:\d+:\d+/)) {
      date = this.newDate(str)
    } else {
      date = new Date(str)
    }

    const y = date.getFullYear()
    const m = this.formatNumber(date.getMonth() + 1)
    const d = this.formatNumber(date.getDate())
    // const h = this.formatNumber(date.getHours())
    // const min = this.formatNumber(date.getMinutes())
    // const s = this.formatNumber(date.getSeconds())

    if (onlyDate) {
      return `${[y, m, d].join('-')}`
    }
    return `${[y, m, d].join('-')} ${['00', '00', '00'].join(':')}`
  },

  // 格式化传入时间的下一天的时间
  formatNextDate(str, onlyDate) {
    if (!str) {
      return ''
    }

    let date
    // 兼容 safari
    if (str.match && str.match(/\d+-\d+-\d+ \d+:\d+:\d+/)) {
      date = this.newDate(str)
    } else {
      date = new Date(str)
    }

    date.setDate(date.getDate() + 1)

    const y = date.getFullYear()
    const m = this.formatNumber(date.getMonth() + 1)
    const d = this.formatNumber(date.getDate())
    // const h = this.formatNumber(date.getHours())
    // const min = this.formatNumber(date.getMinutes())
    // const s = this.formatNumber(date.getSeconds())

    if (onlyDate) {
      return `${[y, m, d].join('-')}`
    }
    return `${[y, m, d].join('-')} ${['00', '00', '00'].join(':')}`
  },

  // 小时 00 00 00
  formatEndDate(str, onlyDate, isDatatime = false) {
    if (!str) {
      return ''
    }

    let date
    // 兼容 safari
    if (str.match && str.match(/\d+-\d+-\d+ \d+:\d+:\d+/)) {
      date = this.newDate(str)
    } else {
      date = new Date(str)
    }

    if(!isDatatime) {
      date.setDate(date.getDate() + 1)
    }

    const y = date.getFullYear()
    const m = this.formatNumber(date.getMonth() + 1)
    const d = this.formatNumber(date.getDate())
    const h = this.formatNumber(date.getHours())
    const min = this.formatNumber(date.getMinutes())
    const s = this.formatNumber(date.getSeconds())
    
    if (onlyDate) {
      return `${[y, m, d].join('-')}`
    }
    return `${[y, m, d].join('-')} ${[h, min, s].join(':')}`
  },

  // dateString 的格式是 xxxx-xx-xx hh:mm:ss
  newDate(dateString) {
    if (dateString.replace) {
      // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
      return new Date(dateString.replace(' ', 'T').concat('.000+08:00'))
    }
    return new Date(dateString)
  },
  excludeNull(obj) {
    for (const key in obj) {
      if (obj[key] === null || (typeof obj[key] === 'string' && obj[key].length === 0)) {
        delete obj[key]
      }
    }
  },

  // 分转成元
  // 小数位为0直接显示整数，有一位显示一位，有多位显示两位
  filterPrice(data) {
    if (data) {
      const yuan = Number(data / 100)
      const fixed0 = yuan.toFixed(0)
      const fixed1 = yuan.toFixed(1)
      const fixed2 = yuan.toFixed(2)

      if (Number(fixed0) === Number(fixed1) && Number(fixed1) === Number(fixed2)) {
        return fixed0
      } if (Number(fixed0) !== Number(fixed1) && Number(fixed1) === Number(fixed2)) {
        return fixed1
      }
      return fixed2
    }
    return 0
  },
  // 加¥
  formatPrice(price) {
    return `¥ ${this.filterPrice(price)}`
  },
  // 元转成分
  filterPriceToFen(data) {
    if (data) {
      return Number(data * 100).toFixed(0)
    }
    return 0
  },
  // 转换对象里的相关属性
  filterObjPriceToFen(Obj, attrs) {
    attrs.forEach(val => {
      Obj[val] = this.filterPriceToFen(Obj[val])
    })
  },
  // 匹配Options数据
  filterOptions(key, options) {
    let status = ''
    options.forEach(obj => {
      if (obj.value === key) {
        status = obj.label
      }
    })
    return status
  },

  // 截取部分字符，多出部分 ...
  subString(str, length) {
    if (!str) return ''

    if (str.length > length) {
      return `${str.substr(0, length)}...`
    }
    return str
  },

  toTTContent(str) {
    return str.replace(new RegExp('<br>', 'gi'), '\n')
  },
  toHtmlContent(str) {
    return str.replace(new RegExp('\n', 'gi'), '<br>')
  },

  // 获取第几日的时间，今日GetDateStr(0)，昨日GetDateStr(-1)，前天GetDateStr(-2)
  GetDateStr(AddDayCount, onlyDate) {
    const dd = new Date()
    dd.setDate(dd.getDate() + AddDayCount)
    const y = dd.getFullYear()
    const m = this.formatNumber(dd.getMonth() + 1)
    const d = this.formatNumber(dd.getDate())
    const h = this.formatNumber(dd.getHours())
    const min = this.formatNumber(dd.getMinutes())
    const s = this.formatNumber(dd.getSeconds())
    if (onlyDate) {
      return `${[y, m, d].join('-')}`
    }
    return `${[y, m, d].join('-')} ${[h, min, s].join(':')}`
  },

  // 开始小时 00 00 00
  GetDateStartStr(AddDayCount, onlyDate) {
    const dd = new Date()
    dd.setDate(dd.getDate() + AddDayCount)
    const y = dd.getFullYear()
    const m = this.formatNumber(dd.getMonth() + 1)
    const d = this.formatNumber(dd.getDate())
    // const h = this.formatNumber(dd.getHours())
    // const min = this.formatNumber(dd.getMinutes())
    // const s = this.formatNumber(dd.getSeconds())
    if (onlyDate) {
      return `${[y, m, d].join('-')}`
    }
    return `${[y, m, d].join('-')} ${['00', '00', '00'].join(':')}`
  },

  // 结束小时 23 59 59
  GetDateEndStr(AddDayCount, onlyDate) {
    const dd = new Date()
    dd.setDate(dd.getDate() + AddDayCount)
    const y = dd.getFullYear()
    const m = this.formatNumber(dd.getMonth() + 1)
    const d = this.formatNumber(dd.getDate())
    // const h = this.formatNumber(dd.getHours())
    // const min = this.formatNumber(dd.getMinutes())
    // const s = this.formatNumber(dd.getSeconds())
    if (onlyDate) {
      return `${[y, m, d].join('-')}`
    }
    return `${[y, m, d].join('-')} ${[23, 59, 59].join(':')}`
  },

  // 获取第几月的时间，今日GetDateStr(0)，昨日GetDateStr(-1)，前天GetDateStr(-2)
  GetMonthStr(AddDayCount, onlyDate) {
    const dd = new Date()
    dd.setMonth(dd.getMonth() + AddDayCount)
    const y = dd.getFullYear()
    const m = this.formatNumber(dd.getMonth() + 1)
    const d = this.formatNumber(dd.getDate())
    const h = this.formatNumber(dd.getHours())
    const min = this.formatNumber(dd.getMinutes())
    const s = this.formatNumber(dd.getSeconds())
    if (onlyDate) {
      return `${[y, m, d].join('-')}`
    }
    return `${[y, m, d].join('-')} ${[h, min, s].join(':')}`
  },

  // 获取最近一个月
  getRecentlyMonth() {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth() + 1
    const day = now.getDate()
    const nowMonthDay = new Date(year, month, 0).getDate()
    const dateObj = {}
    dateObj.end = `${year}-${month}-${day}`
    if (month - 1 <= 0) {
      dateObj.start = `${year - 1}-${12}-${day}`
    } else {
      const lastMonthDay = new Date(year, parseInt(month) - 1, 0).getDate()
      if (lastMonthDay < day) {
        if (day < nowMonthDay) {
          dateObj.start = `${year}-${month - 1}-${lastMonthDay - (nowMonthDay - day)}`
        } else {
          dateObj.start = `${year}-${month - 1}-${lastMonthDay}`
        }
      } else {
        dateObj.start = `${year}-${month - 1}-${day}`
      }
    }
    return dateObj
  },

  formatCurrency(num) {
    // 金额货币化 100 => 100.00
    if (!num) {
      return '0.00' // 金额空时使用标准'0.00'
    }
    num = num.toString().replace(/\$|,/g, '')
    if (isNaN(num)) num = '0'
    const sign = num === (num = Math.abs(num))
    num = Math.floor(num * 100 + 0.50000000001)
    let cents = num % 100
    num = Math.floor(num / 100).toString()
    if (cents < 10) cents = `0${cents}`
    for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) num = `${num.substring(0, num.length - (4 * i + 3))},${num.substring(num.length - (4 * i + 3))}`
    return `${(sign ? '' : '-') + num}.${cents}`
  },
  secondToDate(scond, type) {
    // 秒转时分秒
    let h; let m; let s
    type = type || 'ch' // 不传 默认为 返回为中文
    if (type === 'ch') {
      h = Math.floor(scond / 3600)
      m = Math.floor((scond / 60) % 60)
      s = Math.floor(scond % 60)
      return (scond = `${h} 小时${m} 分${s} 秒`)
    }
    // 否则返回为正常形式
    h = Math.floor(scond / 3600) < 10 ? `0${Math.floor(scond / 3600)}` : Math.floor(scond / 3600)
    m = Math.floor((scond / 60) % 60) < 10
      ? `0${Math.floor((scond / 60) % 60)}`
      : Math.floor((scond / 60) % 60)
    s = Math.floor(scond % 60) < 10 ? `0${Math.floor(scond % 60)}` : Math.floor(scond % 60)
    return (scond = `${h}:${m}:${s}`)
  },
  mulNum(a, b) {
    // 乘法
    let c = 0
    const d = a.toString()
    const e = b.toString()
    try {
      c += d.split('.')[1].length
    } catch (f) {}
    try {
      c += e.split('.')[1].length
    } catch (f) {}
    return (Number(d.replace('.', '')) * Number(e.replace('.', ''))) / Math.pow(10, c)
  },
  divNum(a, b) {
    // 除法
    let c
    let d
    let e = 0
    let f = 0
    try {
      e = a.toString().split('.')[1].length
    } catch (g) {}
    try {
      f = b.toString().split('.')[1].length
    } catch (g) {}
    return (
      (c = Number(a.toString().replace('.', ''))),
      (d = Number(b.toString().replace('.', ''))),
      this.mulNum(c / d, Math.pow(10, f - e))
    )
  },
  addNum(a, b) {
    // 加法
    let c; let d; let e
    try {
      c = a.toString().split('.')[1].length
    } catch (f) {
      c = 0
    }
    try {
      d = b.toString().split('.')[1].length
    } catch (f) {
      d = 0
    }
    return (e = Math.pow(10, Math.max(c, d))), (this.mulNum(a, e) + this.mulNum(b, e)) / e
  },
  subNum(a, b) {
    // 减法
    let c; let d; let e
    try {
      c = a.toString().split('.')[1].length
    } catch (f) {
      c = 0
    }
    try {
      d = b.toString().split('.')[1].length
    } catch (f) {
      d = 0
    }
    return (e = Math.pow(10, Math.max(c, d))), (this.mulNum(a, e) - this.mulNum(b, e)) / e
  },

  getFileExt(file) {
    // 获取文件格式
    return file.substring(file.lastIndexOf('.'))
  },
  downFile(url, filename) {
    const eleLink = document.createElement('a')
    eleLink.download = filename
    eleLink.style.display = 'none'
    eleLink.href = url
    document.body.appendChild(eleLink)
    eleLink.click()
    document.body.removeChild(eleLink)
  },
  copyText(targetId, inputId, self) {
    if (self) {
      self.$message.success('复制成功！')
    }
    const text = document.getElementById(targetId).innerText
    const input = document.getElementById(inputId)
    input.value = text // 修改文本框的内容
    input.select() // 选中文本
    document.execCommand('copy') // 执行浏览器复制命令
  },
  /**
   * @func
   * @desc 获取ship快照
   * @param {String} videoUrl 视频链接
   * @param {Object} opts 处理视频参数
   */
  getVideoSnapshot(videoUrl, opts) {
    let videoCover = `${videoUrl}?x-oss-process=video/snapshot`
    opts = opts || {}
    // 指定截图的宽
    if (opts.t) {
      videoCover = `${videoCover},t_${opts.t}`
    } else {
      videoCover = `${videoCover},t_1000`
    }
    // 指定截图的宽
    if (opts.w) {
      videoCover = `${videoCover},w_${opts.w}`
    }
    // 指定截图的高
    if (opts.h) {
      videoCover = `${videoCover},h_${opts.h}`
    }
    // 指定截图的高
    if (opts.m) {
      videoCover = `${videoCover},m_${opts.m}`
    } else {
      videoCover = `${videoCover},m_fast`
    }
    return videoCover
  },
  // 时间格式化输出，将时间戳转为 倒计时时间
  dateFormat(micro_second) {
    const second = micro_second // 总的秒数
    let datetime = ''
    // 天数位
    const day = Math.floor(second / 3600 / 24)
    let dayStr = day.toString()
    if (dayStr.length === 1) dayStr = `0${dayStr}`

    if (day >= 1) {
      datetime = `${dayStr}天`
    }
    // 小时位
    const hr = Math.floor((second / 3600) % 24)
    // var hr = Math.floor(second / 3600) //直接转为小时 没有天 超过1天为24小时以上

    let hrStr = hr.toString()
    if (hrStr.length === 1) hrStr = `0${hrStr}`

    // 分钟位
    const min = Math.floor((second / 60) % 60)
    let minStr = min.toString()
    if (minStr.length === 1) minStr = `0${minStr}`

    // 秒位
    const sec = Math.floor(second % 60)
    let secStr = sec.toString()
    if (secStr.length === 1) secStr = `0${secStr}`

    datetime += `${hrStr}小时${minStr}分${secStr}秒`
    return datetime
  },
  SecondToDate(msd) {
    let time = msd
    if (time !== null && time !== '') {
      if (time > 60 && time < 60 * 60) {
        time = `${parseInt(time / 60.0)
        }分钟${
          parseInt((parseFloat(time / 60.0) - parseInt(time / 60.0)) * 60)
        }秒`
      } else if (time >= 60 * 60 && time < 60 * 60 * 24) {
        time = `${parseInt(time / 3600.0)
        }小时${
          parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)
        }分钟${
          parseInt(
            (
              parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
              parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)
            ) * 60
          )
        }秒`
      } else if (time >= 60 * 60 * 24) {
        time = `${parseInt(time / 3600.0 / 24)
        }天${
          parseInt((parseFloat(time / 3600.0 / 24) - parseInt(time / 3600.0 / 24)) * 24)
        }小时${
          parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)
        }分钟${
          parseInt(
            (
              parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
              parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)
            ) * 60
          )
        }秒`
      } else {
        time = `${parseInt(time)}秒`
      }
    }
    return time
  },

  // 分 转元、万、亿
  formatPriceNum(num) {
    if (!num || num === 0) return '0'
    let price = ''
    if (num >= 10000000000) {
      price = `${(num / 10000000000).toFixed(4)}亿`
    } else if (num >= 1000000) {
      price = `${(num / 1000000).toFixed(2)}万`
    } else {
      price = `¥${(num / 100).toFixed(2)}`
    }
    return price
  },

  // 是否是手机
  isPhoneAvailable(pone) {
    const reg = /^[1][3-9][0-9]{9}$/;
    if (!reg.test(pone)) {
      return false;
    }
    return true;
  },

  formatTimeDiff(diff) {
    let res = '';
    const days = Math.floor(diff / (24 * 3600 * 1000));
    if (days > 0) {
      res += `${days}天`
    }
    const leave1 = diff % (24 * 3600 * 1000);
    const hours = Math.floor(leave1 / (3600 * 1000));
    if (hours > 0) {
      res += `${hours}小时`
    } else if (res !== '') {
      res += `${hours}小时`
    }

    const leave2 = diff % (3600 * 1000)
    const minutes = Math.floor(leave2 / (60 * 1000))
    if (minutes > 0) {
      res += `${minutes}分`
    } else if (res !== '') {
      res += `${minutes}分`
    }

    const leave3 = diff % (60 * 1000)
    const seconds = Math.floor(leave3 / 1000)
    if (seconds > 0) {
      res += `${seconds}秒`
    } else if (res !== '') {
      res += `${seconds}秒`
    }

    return res;
  }
 }

