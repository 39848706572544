<template>
  <div class="crm-mobile">
    <img src="https://mktv-in-cdn.mockuai.com/16360995199576947.png" alt="" />
    <div class="bottom">
      <div class="btn" @click="dialogVisible = true"></div>
    </div>
    <div
      class="dialog"
      v-if="dialogVisible"
      @click.stop="dialogVisible = false"
    >
      <div class="box" @click.stop="dialogVisible = true">
        <div class="title">联系方式</div>
        <div class="main">
          <el-form :rules="rules" ref="form" :model="form">
            <el-form-item label="手机号码:" prop="mobile">
              <el-input
                v-model="form.mobile"
                placeholder="请输入手机号码"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="footer">
          <button @click.stop="dialogVisible = false" class="close">
            取 消
          </button>
          <button type="primary" @click.stop="submit" class="primary">
            确 定
          </button>
        </div>
      </div>
    </div>
    <div class="message" v-if="msgShow">
      <div class="box">
        <div class="success" v-if="success">
          <img
            src="https://mktv-in-cdn.mockuai.com/16346985951215506.png"
            alt=""
          />
          <span>{{ errMsg }}</span>
        </div>
        <div class="error" v-else>
          <img
            src="https://mktv-in-cdn.mockuai.com/16346985951215506.png"
            alt=""
          />
          <span>{{ errMsg }}</span>
        </div>
        <button class="msg_btn" @click="msgShow = false">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/js/util";
const mobileCheck = (rule, value, callback) => {
  if (!util.isPhoneAvailable(value)) {
    return callback(new Error("请输入正确的手机号"));
  }
  callback();
};

export default {
  name: "CrmMobile",
  data() {
    return {
      form: {
        mobile: "",
      },
      account: "",
      dialogVisible: false,
      errMsg: "",
      success: true,
      msgShow: false,
      rules: {
        mobile: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "change",
          },
          { validator: mobileCheck, trigger: ["blur", "change"] },
        ],
      },
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate((res) => {
        if (res) {
          window.lib.api.post({
            api: "ec/a/boss/crm/user/add/mobile",
            data: {
              mobile: this.form.mobile,
              // account: this.account,
            },
            success: () => {
              this.form.mobile = "";
              // this.account = ''
              this.dialogVisible = false;
              this.$refs.form.resetFields();
              this.msgShow = true;
            },
            error: (err) => {
              this.dialogVisible = false;
              this.$refs.form.resetFields();
              this.errMsg = err.msg;
              this.msgShow = true;
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/common.less";
.crm-mobile {
  // .pxToVw(width, 1920);
  width: 100vw;
  // .pxToVw(height, 1400);
  // background-image: url(https://mktv-in-cdn.mockuai.com/16348733311497159.png);
  // background-repeat: no-repeat;
  // background-size: contain;
  img {
    width: 100%;
    // height: 100vw;
    object-fit: cover;
    display: block;
    margin: 0 auto;
  }
  .bottom {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    .pxToVw(height, 68);
    width: 100%;
    background-image: url(https://mktv-in-cdn.mockuai.com/16363504866298437.png);
    background-repeat: no-repeat;
    background-size: cover;

    .btn {
      .pxToVw(width, 184);
      .pxToVw(height, 45);
      .pxToVw(margin-left, 1046);
      .pxToVw(margin-top, 25);
      .pxToVw(margin-bottom, 29);
      // background-color: red;
      cursor: pointer;
    }
  }
  .footer {
    text-align: right;
  }
  .close,
  .primary {
    .pxToVw(font-size, 14);
    .pxToVw(border-radius, 4);
    .pxToVw(line-height, 14);
    .pxToVw(padding, 10);
    font-family: Helvetica;
    border: 1px #ccc solid;
    cursor: pointer;
  }
  .close {
    .pxToVw(margin-right, 10);
    background: #fff;
    color: #475669;
  }
  .primary {
    background: #306eed;
    color: #fff;
  }
  .dialog {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    .box {
      .pxToVw(width, 390);
      .pxToVw(height, 240);
      .pxToVw(border-radius, 2);
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04),
        0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      border: 1px solid #d3dce6;
      margin: 0 auto;
      position: relative;
      box-sizing: border-box;
      .pxToVw(margin-top, 200);
      .title {
        .pxToVw(font-size, 16);
        .pxToVw(padding, 20);

        font-family: Helvetica;
        color: #1f2d3d;
        line-height: 16px;
      }
      .main {
        .el-form-item {
          display: flex;

          .pxToVw(width, 350);

          /deep/ .el-input__inner {
            .pxToVw(height, 36);
            .pxToVw(font-size, 14);
          }
        }
        .pxToVw(padding, 20);
        /deep/ .el-form-item__label {
          .pxToVw(font-size, 14);
          .pxToVw(width, 85);
          padding-right: 0;
          text-align: left;
        }
        /deep/ .el-form-item__label::before {
          display: none;
        }
        /deep/ .el-form-item__content {
          flex: 1;
        }
      }
      .footer {
        position: absolute;
        .pxToVw(right, 20);
        .pxToVw(bottom, 20);
      }
    }
  }
  .message {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    .box {
      .pxToVw(width, 390);
      .pxToVw(height, 202);
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      margin: 0 auto;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04),
        0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      border-radius: 2px;
      border: 1px solid #d3dce6;
      .pxToVw(margin-top, 200);
      .success,
      .error {
        display: flex;
        align-items: center;
        img {
          display: block;
          .pxToVw(width, 40);
          .pxToVw(height, 40);
          .pxToVw(font-size, 14);
          .pxToVw(padding-left, 20);
          .pxToVw(padding-right, 20);
        }
        .text {
          .pxToVw(font-size, 14);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #475669;
        }
      }
      .msg_btn {
        .pxToVw(width, 64);
        .pxToVw(height, 36);
        .pxToVw(font-size, 14);
        .pxToVw(border-radius, 4);
        background: #306eed;
        border: none;
        position: absolute;
        color: #fff;
        .pxToVw(right, 20);
        .pxToVw(bottom, 15);
        cursor: pointer;
      }
    }
  }
}
</style>
